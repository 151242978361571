import React, {useState, useEffect, createContext} from 'react'
import { Navigate, Route, Routes, useLocation  } from "react-router-dom";
import AdminIndex from './scenes/AdminIndex';
import Test from './scenes/Test.jsx'
import Receitas from './scenes/Receitas/Receitas.jsx'
import NovaReceita from './scenes/Receitas/NovaReceita.jsx';
import EditReceitas from './scenes/Receitas/EditReceitas.jsx'
import LeadsIndex from './scenes/Leads/LeadsIndex.jsx';

import CampanhasIndex from './scenes/Campanhas/CampanhasIndex.jsx';
import FinanceiroIndex from './scenes/Financeiro/FinanceiroIndex.jsx';
import IntegracoesIndex from './scenes/Integracoes/IntegracoesIndex.jsx';
import UsabilidadeIndex from './scenes/Usabilidade/UsabilidadeIndex.jsx';
import UsuariosIndex from './scenes/Usuarios/UsuariosIndex.jsx';
import NewLayout from '../components/NewLayout';
import NovoUsuario from './scenes/Usuarios/NovoUsuario';
import EditarUsuario from './scenes/Usuarios/EditarUsuario';
import Assinaturas from './scenes/Assinaturas/Assinaturas';
import PainelPlanos from './scenes/Assinaturas/PainelPlanos';
import Cupoms from './scenes/Campanhas/Cupoms';
import CampanhasEmail from './scenes/Campanhas/CampanhasEmail';
import CampanhaAvisos from './scenes/Campanhas/CampanhaAvisos';
import Destaques from './scenes/Receitas/Destaques';
import Configuracoes from './scenes/Configuracoes/Configuracoes';
import Categorias from './scenes/Receitas/Categorias';
import PainelTickets from './scenes/Tickets/PainelTickets';
import PainelPagSeguro from './scenes/Integracoes/PainelPagSeguro';



function AdminMain() {

  return (
    <div className="app" >
        <Routes element={<NewLayout />}>
              <Route path="/" element={<AdminIndex />} />
              
              <Route path="/receitas" element={<Receitas />} />
              <Route path="/receita/:id" element={<AdminIndex />} />
              <Route path="/create/receita" element={<NovaReceita />} />
              <Route path="/receitas/edit/:id" element={<EditReceitas />} />
              <Route path="/destaques" element={<Destaques />} />
              <Route path="/categorias" element={<Categorias />} />

              <Route path="/usuarios" element={<UsuariosIndex />} />
              <Route path="/usuario/:id" element={<AdminIndex />} />
              <Route path="/usuario/assinaturas" element={<AdminIndex />} />
              <Route path="/usuario/assinatura/:id" element={<AdminIndex />} />
              <Route path="/usuario/financeiro/:id" element={<AdminIndex />} />
              <Route path="/usuario/movimentacao/:id" element={<AdminIndex />} />
              <Route path="/create/usuario" element={<NovoUsuario />} />
              <Route path="/edit/usuario/:id" element={<EditarUsuario />} />

              <Route path="/assinaturas" element={<Assinaturas />} />
              <Route path="/planos" element={<PainelPlanos />} />

              <Route path="/financeiro" element={<FinanceiroIndex />} />
              <Route path="/financeiro/pagamentos" element={<AdminIndex />} />
              <Route path="/financeiro/pagamento/:id" element={<AdminIndex />} />
              <Route path="/tickets" element={<PainelTickets />} />
              <Route path="/tickets/:id" element={<AdminIndex />} />
              
              <Route path="/usabilidade" element={<UsabilidadeIndex />} />
              
              <Route path="/campanhas" element={<CampanhasIndex />} />
              <Route path="/campanhas/cupoms" element={<Cupoms />} />
              <Route path="/campanhas/emails" element={<CampanhasEmail />} />
              <Route path="/campanhas/avisos" element={<CampanhaAvisos />} />
              
              <Route path="/leads" element={<LeadsIndex />} />

              <Route path="/integracoes/pagseguro" element={<PainelPagSeguro />} />
              <Route path="/configuracoes" element={<Configuracoes />} />

              <Route path="/test" element={<Test />} />
              <Route path="/admin/*" element={<Navigate to="/" replace />} />
        </Routes>
    </div>
  );
}

export default AdminMain;